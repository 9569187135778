<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a
          @click="$router.back()"
          href="#"
          class="
            text-dark-75
            font-weight-bolder
            text-hover-primary
            mb-1
            font-size-lg
            d-flex
            flex-row
            align-items-center
          "
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Histori Pengerjaan Ujian
              </span>
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body pt-4">
            <!--          <template v-slot:header>-->
            <!--            <b-row>-->
            <!--              <b-col>-->
            <!--                <h6 class="mb-0">Daftar Ujian</h6>-->
            <!--                <b-skeleton width="25%" v-if="isLoading"></b-skeleton>-->
            <!--                <span class="mb-0" v-if="!isLoading && currentUjianList[0]">{{ currentUjianList[0].kurikulum.mapel.nama_mapel }}</span>-->
            <!--              </b-col>-->
            <!--              <b-col cols="auto">-->
            <!--                <b-button pill variant="warning" class="mr-1" @click="$router.back()">Kembali</b-button>-->
            <!--                <b-button pill variant="primary" tag="router-link" :to="`/mapel/${idKurikulum}/ujian/create/`">Tambah</b-button>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!--          </template>-->
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
              :items="currentRekapNilaiList.data"
              :fields="isSiswa ? fieldSiswa : fields"
              :busy="isLoading"
              show-empty
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada histori ujian ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada histori ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Deskripsi:
                </div>
                <div v-html="row.item.exam_id.description"></div>
              </template>
              <template #cell(kode_ujian)="data">
                {{ data.item.kode_ujian }}
              </template>
              <template #cell(nama_ujian)="data">
                <!--                  {{ data.item.nama }}-->
                <div v-if="!idKurikulum && isSiswa">
                  <a
                    href="#"
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                    >{{ data.item.nama }}</a
                  >
                </div>
                <div v-else>
                  {{ data.item.nama }}
                </div>
              </template>
              <template #cell(scores)="data">
                <span>
                  -
                </span>
                <!--                <b-badge class="mr-1" pill href="#" variant="info">-->
                <!--                  {{ data.item.waktu }} Menit-->
                <!--                </b-badge>-->
                <!-- v-if="data.item.one_nilai_ujian.status == 'graded'" -->
                <!-- <span
                  :class="`label label-lg label-inline font-weight-bold py-4 label-light-${
                     data.item.scores >= data.item.exam_id.minimum_criteria
                      ? 'info'
                      : 'danger'
                  } mr-1`"
                >
                  {{ Math.round(data.item.scores) }}
                </span> -->
                <!-- <span
                  v-else
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  Menunggu penilaian
                </span> -->
              </template>
              <template #cell(waktu)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info">-->
                <!--                  {{ data.item.waktu }} Menit-->
                <!--                </b-badge>-->
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.remaining_time }} Menit
                </span>
              </template>
              <template #cell(tanggal)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="success">-->
                <!--                  {{ data.item.tanggal_dibuka | moment("dddd, LL HH:mm:ss") }}-->
                <!--                </b-badge>-->
                <!--                - -->
                <!--                <b-badge class="mr-1" pill href="#" variant="danger">-->
                <!--                  {{ data.item.tanggal_ditutup | moment("dddd, LL HH:mm:ss") }}-->
                <!--                </b-badge>-->
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-primary
                    mr-1
                  "
                >
                  {{ data.item.createdAt | moment("dddd, LL HH:mm") }}
                </span>
              </template>
              <template #cell(published)="data">
                <b-form-checkbox
                  v-model="data.item.published"
                  @input="togglePublish(data.item)"
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <!--end::Navigation-->
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { DELETE_UJIAN, UPDATE_UJIAN } from "@/core/services/store/ujian.module";
import { GET_MAPEL } from "@/core/services/store/mapel.module";
import { GET_ONE_ATTEMPT } from "@/core/services/store/attempt.module";
import { GET_HISTORI_NILAI } from "@/core/services/store/rekapnilai.module";
export default {
  name: "HistoryUjian",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fieldSiswa: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "exam_id.name",
          label: "Kode ujian",
          sortable: true,
        },
        {
          key: "exam_id.name",
          label: "Nama ujian",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu Pengerjaan",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal Pengerjaan",
          sortable: true,
        },
        {
          key: "scores",
          label: "Nilai",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_ujian",
          label: "Kode ujian",
          sortable: true,
        },
        {
          key: "nama_ujian",
          label: "Nama ujian",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "published",
          label: "Published",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      selectedMapel: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Histori ujian" }]);
    if (this.isSiswa) this.getData();
    else this.getMapel();
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters([
      "currentUjianList",
      "currentMapelList",
      "currentAttempt",
      "currentRekapNilaiList",
    ]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    options() {
      var opt = [];
      this.currentMapelList.forEach((mapel) => {
        opt.push({
          text:
            mapel.kurikulum.mapel.nama_mapel +
            " - Tingkat " +
            parseInt(mapel.kurikulum.semester / 2 + 1),
          value: mapel.id_kurikulum,
        });
      });
      return opt;
    },
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.currentUser.role == "student";
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMapel() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_MAPEL)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Mata Pelajaran`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_HISTORI_NILAI)
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentRekapNilaiList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Histori Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    togglePublish(item) {
      // item.published = !item.published
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_UJIAN, item)
        .then((response) => {
          this.isLoading = false;
          this.$bvToast.toast(
            `Berhasil ${item.published ? "publish" : "unpublish"} ujian`,
            {
              title: response.message,
              variant: "success",
              solid: true,
            }
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Publish Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    attemptUjian(item) {
      const overlay = this.$loadingOverlay.show();
      this.$store
        .dispatch(GET_ONE_ATTEMPT, item.id)
        .then(() => {
          overlay.hide();
          this.$router.push("attempt");
        })
        .catch(() => {
          overlay.hide();
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteUjian(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus ujian ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_UJIAN, item.id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus ujian`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Publish Ujian`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>

<style scoped>
</style>
